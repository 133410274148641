import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, Typography, AppBar, Toolbar, Button, Select, MenuItem, InputLabel, FormControl, OutlinedInput, Chip } from '@mui/material';
import JsonPretty from 'react-json-pretty';
import axios from 'axios';
import PopupForm from './PopupForm';
import { Toaster, toast } from 'react-hot-toast';
import { grey } from '@mui/material/colors';


const BotLogsV2 = () => {
    const [activeGroup, setActiveGroup] = useState(null);
    const [data, setData] = useState(null);
  
    const showErrorToast = (error) => {
      toast.error((t) => (
        <div>
          <p>Error Encountered!</p>
          <strong>----- {error.response?.data} -----</strong>
          <p>Error message: {error.message}</p>
        </div>
      ));
    };
  
    const getBotData = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const dateParam = urlParams.get("date");
      const botId = urlParams.get("botId");
      const apiUrl = `https://history.funner.co.il/api/services/getBotStatistics?startTime=${dateParam}&endTime=${dateParam}&botId=${botId}`;
      axios
        .get(apiUrl)
        .then((response) => {
          setData(response.data);
          console.log("response with logs", response.data, dateParam);
          toast.success("Data fetched successfully!");
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          showErrorToast(error);
        });
    };
  
    const handleOpenLogs = (group) => {
      setActiveGroup(group);
    };
  
    const handleCloseLogs = () => {
      setActiveGroup(null);
    };
  
    useEffect(() => {
      getBotData();
    }, []);
  
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          Groups
        </Typography>
        <Toaster />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Flow ID</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((group) => (
                <TableRow key={group.FlowId}>
                  <TableCell>{group.FlowId}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenLogs(group)}
                    >
                      View Logs
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
  
        {activeGroup && (
          <Dialog
            open={!!activeGroup}
            onClose={handleCloseLogs}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>Logs for Flow ID: {activeGroup.FlowId}</DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Step ID</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Message Content</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeGroup.Logs.map((log, index) => {
                      let messageContent = null;
                      if (
                        log.StepStatus === "QuestionAsked" &&
                        log.MessageObj &&
                        typeof log.MessageObj === "string"
                      ) {
                        try {
                          const parsedMessage = JSON.parse(log.MessageObj);
                          console.log("parsedMessage", parsedMessage);
                          messageContent = parsedMessage?.Message || null;
                        } catch (error) {
                          console.error("Error parsing Message JSON:", error);
                        }
                      }
  
                      return (
                        <TableRow key={index}>
                          <TableCell>{log.StepId}</TableCell>
                          <TableCell>{log.StepStatus}</TableCell>
                          <TableCell>
                            {log.StepStatus === "QuestionAsked"
                              ? messageContent || "N/A"
                              : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCloseLogs}
                style={{ marginTop: "10px" }}
              >
                Close
              </Button>
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  };
  
  export default BotLogsV2;