import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, Typography, AppBar, Toolbar, Button, Select, MenuItem, InputLabel, FormControl, OutlinedInput, Chip } from '@mui/material';
import JsonPretty from 'react-json-pretty';
import axios from 'axios';
import PopupForm from './PopupForm';
import { Toaster, toast } from 'react-hot-toast';
import { grey } from '@mui/material/colors';

function TableWithApiData() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(true);
  const [tableData, setTableData] = useState([]);
  const getBackgroundColor = (itemCount) => {
    const yellowValue = Math.min(255, 255 - itemCount * 25); // Decrease yellow value based on count
    return `rgb(255, ${yellowValue}, 0)`; // Yellow color range
  };
  useEffect(() => {
    const filtered = data.filter(row => selectedCategories.length === 0 || selectedCategories.includes(row.UpdateType));
    setFilteredData(filtered);
  }, [data, selectedCategories]);

  const showErrorToast = (error) => {
    toast.error((t) => (
      <div>
        <p>Error Encountered!</p> {/* This acts as the title */}
        <strong>----- {error.response?.data} -----</strong>
        <p>Error message: {error.message}</p>
      </div>
    ));
  };
  const fetchTableData = (chatUserPhoneId, companyId) => {
    axios.get(`https://prioritysdk.funner.co.il/api/services/fireberryExtPerformanceLogs?companyId=${companyId}`)
      .then(response => {
        try {
          console.log("response.data", response.data);
          setTableData(response.data);
      } catch (error) {
          console.error("Failed to fetch data", error);
      }
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        showErrorToast(error);
        setPopupOpen(false); // Close popup even on error
      });
  };

  const handleRefreshClick = () => {
    setPopupOpen(true); // Reopen the popup form
  };

  const handleClickOpen = (data) => {
    setOpen(true);
    setSelectedData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCategory = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
  };
  var backgroundColor = "green";
  return (
    <div>
      {popupOpen && <PopupForm onSubmit={fetchTableData} companiesEndpoint={'https://prioritysdk.funner.co.il/api/services/fireberryExtPerfCompanies'} showPhoneInput={false} />}
      {/* <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Data Viewer
          </Typography>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="category-mutiple-chip-label">Filter Categories</InputLabel>
            <Select
              labelId="category-mutiple-chip-label"
              multiple
              value={selectedCategories}
              onChange={handleChangeCategory}
              input={<OutlinedInput id="select-multiple-chip" label="Filter Categories" />}
              renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} style={{ margin: 2 }} />
                  ))}
                </div>
              )}
            >
              {['SaveBotLog', 'actionResult', 'generalErrors'].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button color="inherit" onClick={handleRefreshClick}>
            Refresh Data
          </Button>
        </Toolbar>
      </AppBar> */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CompanyId</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Event Name</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((group, groupIndex) => (
              <React.Fragment key={groupIndex}>
                {/* Separate groups with a clear row */}
                {groupIndex !== 0 && <TableRow><TableCell colSpan={4} sx={{ border: 0 }}><hr /></TableCell></TableRow>}

                {/* Rows in each group */}
                {group.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      backgroundColor: getBackgroundColor(group.length),
                    }}
                  >
                    <TableCell>{row.CompanyId}</TableCell>
                    <TableCell style={{width:"50px"}}>{row.url}</TableCell>
                    <TableCell>{row.action}</TableCell>
                    <TableCell>{new Date(row.date).toISOString().slice(14, 23)}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Toaster />
    </div>
  );
}

function FireberryExtPerformance() {
  return <TableWithApiData />;
}

export default FireberryExtPerformance;
