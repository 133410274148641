import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';

function PopupForm({ onSubmit, companiesEndpoint, showPhoneInput = true }) {
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [chatUserPhoneId, setChatUserPhoneId] = useState('');
  const [open, setOpen] = useState(true);

  useEffect(() => {
    axios.get(companiesEndpoint)
      .then(response => {
        setCompanies(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch companies', error);
      });
  }, []);

  const handleConfirm = () => {
    if (company) {
      onSubmit(chatUserPhoneId, company.CompanyId);
      setOpen(false);
    } else {
      alert("Please select a company.");
    }
  };

  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>Enter Details</DialogTitle>
      <DialogContent>
        <Autocomplete
          value={company}
          onChange={(event, newValue) => {
            setCompany(newValue);
          }}
          options={companies}
          getOptionLabel={(option) => option.CompanyName}
          renderInput={(params) => <TextField {...params} label="Select Company" variant="standard" />}
        />
       {showPhoneInput ?? <TextField
          margin="dense"
          id="chatUserPhoneId"
          label="Chat User Phone ID"
          type="text"
          fullWidth
          variant="standard"
          value={chatUserPhoneId}
          onChange={(e) => setChatUserPhoneId(e.target.value)}
        />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PopupForm;
