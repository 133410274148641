import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, Typography, AppBar, Toolbar, Button, Select, MenuItem, InputLabel, FormControl, OutlinedInput, Chip } from '@mui/material';

const PushNotificationsLogs = () => {
    const [tableData, setTableData] = useState([]);
    const getBackgroundColor = (itemCount) => {
      const yellowValue = Math.min(255, 255 - itemCount * 25); // Decrease yellow value based on count
      return `rgb(255, ${yellowValue}, 0)`; // Yellow color range
    };
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const funnerUserPhoneNumber = urlParams.get('phoneNumber');
        const apiUrl = `https://prioritysdk.funner.co.il/api/services/pushLogs?phoneNumber=${funnerUserPhoneNumber}`;

        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                console.log("response.data", response.data);
                
                setTableData(response.data);
            } catch (error) {
                console.error("Failed to fetch data", error);
            }
        };

        fetchData();
    }, []); // This effect runs only once after the initial render
   
    return (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CompanyId</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Event Name</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((group, groupIndex) => (
                <React.Fragment key={groupIndex}>
                  {/* Separate groups with a clear row */}
                  {groupIndex !== 0 && <TableRow><TableCell colSpan={4} sx={{ border: 0 }}><hr /></TableCell></TableRow>}
    
                  {/* Rows in each group */}
                  {group.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        backgroundColor: getBackgroundColor(group.length),
                      }}
                    >
                      <TableCell>{row.CompanyId}</TableCell>
                      <TableCell>{row.FunnerUserPhoneNumber}</TableCell>
                      <TableCell>{row.EventName}</TableCell>
                      <TableCell>{new Date(row.Date).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
};

export default PushNotificationsLogs
